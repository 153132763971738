import "./aboutUs.scss";
import React, { useEffect, useState } from "react";
import { Col, Button, Row } from "antd";
import feature from "../../assets/images/feature.png";
import feature_2 from "../../assets/images/feature2.png";
function aboutUs() {
  return (
    <div className="about-us container">
      <Row>
        <Col lg={24} span={24}>
          <main id="content" role="main" className>
            <div style={{ clear: "both" }} />
            {/* Begin Content */}
            <div id="system-message-container"></div>
            <content>
              <div
                className="item-page"
                itemScope
                itemType="http://schema.org/Article"
              >
                <meta itemProp="inLanguage" content="en-GB" />
                <div className="page-header">
                  <a href="/about-us" itemProp="url">
                    {" "}
                    <h2>About Us</h2>
                  </a>
                </div>
                <div itemProp="articleBody">
                  <p style={{ fontSize: "18px" }}>
                    <b>
                      “90% online time are on apps and chat app rank no.1. We use chat apps every hour, every day.
                      20,000,000,000 messages are sent between business and users monthly on Facebook Messenger.”
                    </b>
                  </p>
                  <h2 id="pp-general">Why don’t you do business with your customers in their favorite app?</h2>
                  <p>
                    With PingMe, your customers can “ping” you right at where they are: Facebook Messenger, WhatsApp, Instagram.
                    <br />
                    No need to set up your own chatbot!
                    Engage with them where they already are - in chat.
                    Keep conversions persistent across your Shopify store and your Facebook fanpage or Instagram shop.
                    <br />
                    PingMe helps place your brand right next to customers’ friends and family in their address book in the preferred app.
                    PingMe gives you more than a bubble chat!
                    <br />
                    <br/>
                    <b>All-in-one chat platform:</b>{" "} Integrate Messenger, Instagram, WhatsApp live chat, and even more to your store. Manage all inboxes in one platform.
                    <br/>
                    <b>Fast and Easy:</b>{" "}
                     Set up live chat within a minute. Deliver real-time messages for better support. Save lots of operating costs for your store.
                    <br />
                    <b>Turn your chat into revenue:</b>{" "}
                    Conversations can be continued across various device platforms and websites. Easy re-targeting campaign with all-in-one inbox
                  </p>
                </div>
              </div>
            </content>
            {/* End Content */}
          </main>
        </Col>
      </Row>
      <Row type="flex" style={{ alignItems: "center", marginTop: "20px" }}>
        <Col lg={12} span={24}>
          <div>
            <img width="80%" src={feature_2} alt="123" />
          </div>
        </Col>
        <Col lg={12} span={24}>
          <div>
            <img width="80%" src={feature} alt="123" />
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default aboutUs;
