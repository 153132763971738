import './Footer.scss';
import logo from '../assets/images/pingme/logo3.png';
import {
	BrowserRouter as Router,
	Link,
	Route,
	useHistory,
	Redirect,
	Switch,
	useLocation,
} from 'react-router-dom'; 

function FooterSasi() {
	const history = useHistory();
	const handleLogin = () => {
		history.push('/about-us');
	};

	return (
		<div className="footer-sasi container">
			<div className="footer-left">
				<div className="info-sasi">Live chat with shoppers on Facebook Messenger, Instagram, Whatsapp and more</div>
				<div className="copy-right">
					<div>
						<span>©2021 PingMe by Omega</span>{' '}
						<span style={{ marginLeft: '10px' }}>
							<Link to="/about-us">About Us</Link>
						</span>{' '}
						<span style={{ marginLeft: '10px' }}>
							<Link to="/privacy-policy">Privacy Policy</Link>
						</span>{' '}
						<span style={{ marginLeft: '10px' }}>
							<Link to="/terms-of-use">Terms of use</Link>
						</span>{' '}
					</div>
				</div>
			</div>
			<div className="footer-right">
				<div className="logo-wrapper">
					<img
						style={{ width: '65px', borderRadius: '5px' }}
						className="img-fluid w-100"
						src={logo}
						alt="Logo"
					></img>
					<div className="title-wrapper justify-content-center" style={{justifyContent: "center"}}>
						<span>PingMe by Omega</span>
						<span>
							by{' '}
							<a href="https://apps.omegatheme.com/" target="_blank" rel="noreferrer">
								Omega
							</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FooterSasi;
